<template>
  <div class="pt-10">
    <div class="pl-sm-16 pr-sm-16 mx-auto" style="max-width:960px">
      <template v-if="!order.show">
        <v-card class="overflow-hidden">
          <v-toolbar flat color="teal lighten-2">
            <v-icon color="white">mdi-account</v-icon>
            <v-toolbar-title class="font-weight-light white--text">订单查询</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-form ref="form" v-model="order.valid">
              <v-text-field
                color="grey darken-2"
                label="姓名*"
                required
                v-model="order.username"
                :rules="rules.username"
              ></v-text-field>
              <v-text-field
                color="grey darken-2"
                label="手机号*"
                required
                v-model="order.mobile"
                :rules="rules.mobile"
              ></v-text-field>

              <v-text-field
                color="grey darken-2"
                label="订单号*"
                required
                v-model="order.oid"
                :rules="rules.oid"
              ></v-text-field>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="Find()" :disabled="!order.valid">查 询</v-btn>
          </v-card-actions>
        </v-card>
      </template>

      <template v-if="order.show">
        <v-card class="overflow-hidden">
          <v-toolbar flat color="teal lighten-2">
            <v-icon color="white">mdi-book-information-variant</v-icon>
            <v-toolbar-title class="font-weight-light white--text">订单详情</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row class="pl-5">
              <v-col cols="12" class="pa-1 text-subtitle-1 font-weight-black">商户订单号:{{order.oid}}</v-col>
              <v-col cols="12" class="pa-1 mt-n4">{{order.paytime}}</v-col>
              <v-col cols="12" class="pa-1">产品名称：{{order.subjec}}</v-col>
              <v-col cols="12" class="pa-1">购买数量：{{order.quantity}}</v-col>
              <v-col cols="12" class="pa-1">
                支付金额：{{order.totalprice}}
                <span v-show="Math.round(order.totalprice) === order.totalprice">.00</span>
                <span>元</span>
              </v-col>
              <v-col cols="12" class="pa-1">许可证密钥:</v-col>
              <v-col cols="1" class="pa-1"></v-col>
              <v-col cols="8" class="pa-1">
                <v-card label color="teal lighten-5 pa-4 " flat max-width="270px">
                  <div
                    class="text-center"
                    v-for="(stock,index) in this.order.stocks"
                    :key="index"
                  >{{stock.sn}}</div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="email.alert=true" v-show="email.btn">重发邮件</v-btn>

            <v-btn color="success" @click="order.show=fase">查询</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </div>
    <v-dialog v-model="email.alert" max-width="290">
      <v-card>
        <v-card-title class="headline">重发邮件</v-card-title>

        <v-card-text v-if="!email.sendok">
          许可证密钥将发送至：
          <br />
          {{order.email}} ！
        </v-card-text>
        <v-card-text v-else>许可证密钥已经重发完成 ！</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="Resendmail()" v-if="!email.sendok">发送</v-btn>
          <v-btn v-if="!email.sendok" color="green darken-1" text @click="email.alert=false">取消</v-btn>
          <v-btn v-else color="green darken-1" text @click="email.alert=false">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  mounted() {
    console.log("检查oid与safecode");
    if (this.$route.query.oid && this.$route.query.safecode) {
      this.order.oid = this.$route.query.oid;
      this.order.safecode = this.$route.query.safecode;
      this.Find();
    }
  },
  methods: {
    Resendmail: async function () {
      await this.$http.get("webapi/order/resendmail", {
        params: { oid: this.order.oid, safecode: this.order.safecode },
      });
      this.email.btn = false;
      this.email.sendok = true;
    },
    Find: async function () {
      const res = await this.$http.get("webapi/order/getorder", {
        params: {
          oid: this.order.oid,
          username: this.order.username,
          mobile: this.order.mobile,
          safecode: this.order.safecode,
        },
      });
      this.order.status = res.data.status;
      switch (res.data.status) {
        case -1:
        case -2:
          this.$fire({
            title: "订单无效",
            text: "未查询到您的订单，请确认您的输入是否正确。",
            footer: "<a href='https://nod32store.com'>重新下单?</a>",
            type: "error",
          });
          break;
        case 0:
          this.$fire({
            title: "订单未支付",
            text: "您当前订单处于未支付状态，请重新下单。",
            footer: "<a href='https://nod32store.com'>重新下单?</a>",
            type: "error",
          });
          break;
        case 2:
          this.order.stocks = res.data.stocks;
          this.order.subjec = res.data.subjec;
          this.order.quantity = res.data.quantity;
          this.order.totalprice = res.data.totalprice;
          this.order.paytime = res.data.paytime;
          this.order.safecode = res.data.safecode;
          this.order.email = res.data.email;
          this.order.show = true;
          break;
      }
    },
  },
  data() {
    return {
      email: {
        btn: true,
        alert: false,
        sendok: false,
      },
      order: {
        show: false,
        username: "",
        oid: "",
        safecode: "",
        email: "",
        stocks: {},
        quantity: 0,
        subjec: "",
        totalprice: 0,
        mobile: "",
        paytime: "",
        valid: false,
        status: 0,
      },
      rules: {
        oid: [
          (v) =>
            /^20(([1][5])|([2][0]))\d{5}$/.test(v) ||
            "请输入9位订单号，如：202012345",
        ],
        username: [
          (v) => !!v || "请输入您的姓名(2到10个字)",
          (v) => v.length <= 10 || "姓名不能超过10个字符",
          (v) => v.length >= 2 || "姓名不能小于2个字符",
        ],
        mobile: [
          (v) => /^1[3|4|5|7|8][0-9]\d{8}$/.test(v) || "请输入正确的手机号",
        ],
      },
    };
  },
};
</script>