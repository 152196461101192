import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Orders from '../views/orders.vue'
import Business from '../views/Business.vue'
Vue.use(VueRouter)

  const routes = [

    {
      path: '/Business',
      name: 'Business',
      component: Business,
      meta:{
        title:'商业用途 - ESET NOD32 在线购买'
      }
    },
    {
      path: '/orders',
      name: 'Orders',
      component: Orders,
      query:{
        oid:'',
        safecode:''
      },      
      meta:{
        title:'订单查询 - ESET NOD32 在线购买'
      }
    },    
    {
      path: '/',
      name: 'Home',
      component: Home,
      query:{
          oid:'',
          safecode:''
      },
      props: true,
      meta:{
        title:' ESET NOD32 在线购买'
      }
    }  
 
]

const router = new VueRouter({
  //mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
export default router
