<template>
  <div>
    <!--              订单填写           -->
    <v-dialog v-model="dialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">请填写您的订单信息</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="order.valid">
            <v-container>
              <v-row>
                <v-col cols="12" class>
                  <div class="text-h6">{{dialog.product.subjec}}</div>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-select
                    :items="[1,2,3,4,5,6,7,8,9,10]"
                    label="数量*"
                    required
                    v-model="order.quantity"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="8" md="4">
                  <v-text-field
                    label="姓名*"
                    required
                    v-model="order.username"
                    :rules="rules.username"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="8" md="6">
                  <v-text-field
                    label="手机号"
                    hint="手机号方便您找回订单"
                    required
                    v-model="order.mobile"
                    :rules="rules.mobile"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    label="邮箱*"
                    hint="您填写的邮箱将用来发送许可证密钥"
                    required
                    v-model="order.email"
                    :rules="rules.email"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-container class="text-h6 grey--text text--darken-2">
            <span class>订单金额：</span>
            <span class="text-h5 grey--text text--darken-4">{{dialog.product.price*order.quantity}}</span>
            <span
              v-show="Math.round(dialog.product.price*order.quantity) === dialog.product.price*order.quantity"
            >.00</span>
            <span>元</span>
          </v-container>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog.show = false">取消</v-btn>
          <v-btn color="blue darken-1" text @click="createorder()" :disabled="!order.valid">立即购买</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--              等待支付           -->
    <v-dialog v-model="waitpay.show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline" v-if="this.order.status==0">正在等待支付</span>
          <span class="headline" v-else-if="this.order.status==1 && this.order.progress>0">正在等待发货</span>
          <span class="headline" v-else-if="this.order.status==1 && this.order.progress==0">发货超时</span>
          <span class="headline" v-else-if="this.order.status==2">订单已完成</span>
          <span class="headline" v-else>订单状态错误</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row dense>
              <v-col
                cols="12"
                class="text-center"
                v-if="(this.order.status==0||this.order.status==1) && (this.order.progress>0)"
              >
                <v-progress-circular
                  :rotate="-90"
                  :size="130"
                  :width="15"
                  :value="order.progress / 1.2"
                  color="primary"
                >剩余{{ order.progress }}秒</v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                class="text-center"
                v-else-if="this.order.status==0 && this.order.progress==0"
              >
                <a :href="this.order.payurl" target="_blank">支付超时,您可以点击此处重新支付，或者取消订单后重新购买。</a>
                <v-divider></v-divider>
              </v-col>
              <v-col
                cols="12"
                class="text-left blue--text text--blue-darken-4"
                v-else-if="this.order.status==1 && this.order.progress==0"
              >
                <div>1.您可以记录下本页信息并关闭页面，稍后系统会为您自动处理。</div>
                <div>2.系统处理完成后会将许可证密钥发送至您的邮箱：{{this.order.email}}</div>
                <div>3.如果邮箱填写错误，请与客服联系修改。</div>
                <br />
                <v-divider></v-divider>
              </v-col>

              <v-col cols="3" sm="2" align-self="start" class="text-right">订单编号：</v-col>
              <v-col cols="9">{{order.oid}}</v-col>

              <v-col cols="3" sm="2" class="text-right">产品名称：</v-col>
              <v-col cols="9">{{dialog.product.subjec}}</v-col>
              <v-col cols="3" sm="2" class="text-right">数量：</v-col>
              <v-col cols="9">{{order.quantity}}</v-col>
              <v-col cols="3" sm="2" class="text-right">订单金额：</v-col>
              <v-col cols="9" class="text-left">
                {{dialog.product.price*order.quantity}}
                <span
                  v-show="Math.round(dialog.product.price*order.quantity) === dialog.product.price*order.quantity"
                >.00</span>元
              </v-col>
            </v-row>

            <v-row dense v-show="this.order.status==2">
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="3" sm="2" class="text-right">许可证：</v-col>
              <v-col cols="9" class="text-left">
                <div v-for="(stock,index) in this.order.stocks" :key="index">{{stock.sn}}</div>
              </v-col>
              <v-col cols="3" sm="2"></v-col>
              <v-col cols="9" class="text-left">我们同时会将许可证发送致您的邮箱.</v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="blue darken-1"
            text
            @click="waitpay.show = false"
            v-if="this.order.status==2"
          >确定</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="waitpay.show = false"
            v-else-if="this.order.status==1"
          >退出</v-btn>
          <v-btn color="blue darken-1" text @click="waitpay.show = false" v-else>取消订单</v-btn>
          <v-btn color="blue darken-1" text @click="repay" v-if="this.order.status==0">重新支付</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.showfail" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">此商品已下架</v-card-title>

        <v-card-text>对不起，当前商品已无法购买，请选择其它商品。</v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.showfail = false">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    methods: {
    test:async function(){
       // router.push('/order')
       //this.$route.go({path:'/order',
       //                   query:{oid:'11312313',safecode:'aaaa'}})
       //console.log(this.$route)
       this.$router.push({path:'/orders',
                          query:{oid:'202012540',safecode:'787f60e0c0'}})


    },
    justbuy: async function (pid) {
      this.order.status=0
      this.order.oid=0
      if (this.$route.query.oid && this.$route.query.safecode)
      {
          console.log(this.$route.query.oid)

      }
      const res = await this.$http.get("webapi/order/getprdbyid?pid=" + pid);
      var data = res.data;

      if (data.Product) {
        this.dialog.product = data.Product[0];
        this.order.pid = data.Product[0].pid;
        this.dialog.show = true;
      } else {
        this.dialog.showfail = true;

      }
    },
    createorder: async function () {
      console.log("createorder");
      this.order.status=0
      this.order.oid=0
      const res = await this.$http.post("webapi/order/get_payurl", this.order);
      this.order.payurl = res.data.payurl;

      var windowObjectReference = window.open(res.data.payurl, "_blank");
      this.order.oid = res.data.oid;
      this.order.safecode = res.data.safecode;
      if (windowObjectReference) {
        //
      }
      this.order.progress = 120;
      this.dialog.show = false;
      this.waitpay.show = true;
      this.timer = setTimeout(this.checkorder, 1000);
    },
    checkorder: async function () {
      if (
        this.order.progress >= 1 &&
        (this.order.status == 0 || this.order.status == 1)
      ) {
        this.order.progress = this.order.progress - 1;
        clearTimeout(this.order.timer);
        this.timer = window.setTimeout(this.checkorder, 1000);
        if (this.order.progress % 2 == 0) {
          const res = await this.$http.get("webapi/order/getorder", {
            params: { oid: this.order.oid, safecode: this.order.safecode },
          });

          if (this.order.status == 0 && res.data.status == 1) {  //状态从0变为1,变更为等待发货，等待20秒
            this.order.progress = 20;
          } else if (res.data.status==2)
          {
            //  
            this.$router.push({path:'/orders',
                          query:{oid: this.order.oid, safecode: this.order.safecode}})

          }

          this.order.stocks = res.data.stocks;
          this.order.status = res.data.status;
        }
      } else {
        clearTimeout(this.order.timer);
      }
    },
    repay: async function () {
      //this.order.oid=202011792
      //this.order.safecode='091b9e210e'
      this.order.progress = 120;
      if (
        !this.order.timer._idleTimeout ||
        this.order.timer._idleTimeout == -1
      ) {
        this.order.timer = window.setInterval(this.checkorder, 1000);
        console.log("create time");
      }
      window.open(this.order.payurl, "_blank");
    },
  },
  data() {
    return {
              waitpay: {
        show: false,
      },
      dialog: {
        show: false,
        showfail: false,
        product: { subjec: "", price: 0 },
      },
      order: {
        valid: false,
        username: "",
        email: "",
        mobile: "",
        quantity: 1,
        pid: 0,
        isinvoice: 0,
        oid: 0,
        safecode: "",
        payurl: "",
        progress: 120,
        status: 0,
        timer: {},
      },
      rules: {
        username: [
          (v) => !!v || "请输入您的姓名(2到10个字)",
          (v) => v.length <= 10 || "姓名不能超过10个字符",
          (v) => v.length >= 2 || "姓名不能小于2个字符",
        ],
        //email:[v=>/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)||'请输入有效的email，您填写的邮箱将用来发送许可证密钥'],
        email: [
          (v) =>
            /(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
              v
            ) || "请输入有效的email，您填写的邮箱将用来发送许可证密钥",
        ],
        mobile: [
          (v) => /^1[3|4|5|7|8|9][0-9]\d{8}$/.test(v) || "请输入正确的手机号",
        ],
      },
    }
  },
};
</script>
