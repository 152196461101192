<template>
  <div class="pt-10">






    <div
      style="width:947px"
      class="mx-auto grey--text text--darken-2 text-caption"
    >
      <v-row no-gutters align="center">
        <v-col cols="2" class="text-right d-none d-sm-inline">
          <v-img
            src="https://static.eset.com.cn/estore/img/Boxes/EIS.png"
            width="119"
          ></v-img>
        </v-col>

        <v-col class="text-left">
          <span class="text-h5">ESET INTERNET SECURITY 安全套装</span>
          <span class="text-body-1 d-flex d-sm-inline grey--text text--darken-1"
            >为日常网络用户提供终极保护！</span
          >
          <div>安全防范病毒和间谍软件 | 银行和网上购物更安全</div>
          <div>
            阻止黑客访问您的电脑 | 让您的孩子网络安全 |
            网络摄像头和家用路由器使用更安全
          </div>
         
          <v-radio-group v-model="options.esspid" dens>
             
            <v-radio value="3">
              <template v-slot:label>
                <div class="body-2">ESET 网络安全套装1用户：194.00元/3年
                                    <span
                    class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption"
                    >龙年特惠!</span>
                </div>
              </template>
            </v-radio>
        
            <v-radio value="5">
              <template v-slot:label>
                <div class="body-2">
                  ESET 网络安全套装3用户：188.00元/1年
                  <span
                    class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption"
                    >同时保护3台电脑!</span>
                </div>
              </template>
            </v-radio>

            <v-radio value="45">
              <template v-slot:label>
                <div class="body-2">
                  ESET 网络安全套装 3用户：288.00元/3年
                  <span
                    class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption"
                    >1.6折！限时特惠</span
                  >
                </div>
              </template>
            </v-radio>
            
      <!--
            <v-radio value="9">
              <template v-slot:label>
                <div class="body-2">ESET 网络安全旗舰版1用户：462.00元/3年</div>
                <span class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption">终极保护</span>
              </template>
            </v-radio>
-->
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-none d-sm-inline"></v-col>
        <v-col>
          同时支持Windows,macOS平台安装！(需要下载对应平台的安装程序)
          <br />注意:不支持服务器操作系统的安装.
          <br />注意:本商品不支持7天无理由退货,仅限在中国大陆地区激活使用.
          <br />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <v-btn
            color="success"
            dark
            large
            @click.stop="$refs.orderhelp.justbuy(options.esspid)"
            >立即购买</v-btn
          >
        </v-col>
      </v-row>
      <v-divider class="mt-10 mb-10"></v-divider>
    </div>


    <div
      style="width:947px"
      class="mx-auto grey--text text--darken-2 text-caption"
    >
      <v-row no-gutters align="center">
        <v-col cols="2" class="text-right d-none d-sm-inline">
          <v-img
            src="https://static.eset.com.cn/estore/img/Boxes/EAV.png"
            width="119"
          ></v-img>
        </v-col>

        <v-col class="text-left">
          <span class="text-h5">ESET NOD32 防病毒软件</span>
          <span class="text-body-1 d-flex d-sm-inline grey--text text--darken-1"
            >为日常网络用户提供终极保护！</span
          >
          <div>安全防范病毒和间谍软件 | 银行和网上购物更安全</div>
          <div>
            阻止黑客访问您的电脑 | 让您的孩子网络安全 |
            网络摄像头和家用路由器使用更安全
          </div>
          
          <v-radio-group v-model="options.eavpid" class="MyRadio" dense>

                        <v-radio value="2">
              <template v-slot:label>
                <div class="body-2">
                  ESET NOD32防病毒软件 3年1用户版：118.00元/3年

                  
                </div>
              </template>
            </v-radio>

            <v-radio value="1">
              <template v-slot:label>
                <div class="body-2">
                  ESET NOD32防病毒软件 1年1用户版：88.00元/年
                </div>
              </template>
            </v-radio>


          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-none d-sm-inline"></v-col>
        <v-col>
          只支持 Windows 操作系统下安装！
          <br />注意:不支Linux、不支持macOS、不支持Windows Server.
          <br />注意:本商品不支持7天无理由退货,仅限在中国大陆地区激活使用.
          <br />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <v-btn
            color="success"
            dark
            large
            @click.stop="$refs.orderhelp.justbuy(options.eavpid)"
            >立即购买</v-btn
          >
        </v-col>
      </v-row>
      <v-divider class="mt-10 mb-10"></v-divider>
    </div>


    <div
      style="width:947px"
      class="mx-auto grey--text text--darken-2 text-caption"
    >
      <v-row no-gutters align="center">
        <v-col cols="2" class="text-right d-none d-sm-inline">
          <v-img
            src="https://static.eset.com.cn/estore/img/Boxes/ESSP.png"
            width="119"
          ></v-img>
        </v-col>

        <v-col class="text-left">
          <span class="text-h5">ESET File Security</span>
          <span class="text-body-1 d-flex d-sm-inline grey--text text--darken-1"
            >服务器防病毒软件</span
          >
          <div>内置防病毒 | 储存扫描 | 64位扫描引擎 | UEFI扫描仪</div>
          <div>漏洞利用防护 | Hyper-V扫描 | 集群支持 | 模块化安装</div>
          <v-radio-group v-model="options.efspid" class="MyRadio" dense>
            <v-radio value="47" label>
              <template v-slot:label>
                <div class="body-2">
                  ESET File Security 3年期/1台计算机：2180.00元/年
                  <span
                    class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption"
                    >适用于Windows 服务器</span
                  >
                </div>
              </template>
            </v-radio>
            <v-radio value="44" label v-if="!isProduction">
              <template v-slot:label>
                <div class="body-2">
                  测试产品，不发货：0.01元
                  <span
                    class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption"
                    >适用于Windows 服务器</span
                  >
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-none d-sm-inline"></v-col>
        <v-col>
          只支持下列操作系统：<br />
          Microsoft Windows Server 2016/2012 R2 / 2012/2008 R2 / 2008/2003 R2 /
          2003<br />
          Microsoft Windows小型企业服务器2011/2008/2003 R2 / 2003<br />
          Microsoft Windows服务器核心2016/2012/2008 R2 / 2008<br />
          注意:本商品不支持7天无理由退货,仅限在中国大陆地区激活使用.<br />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <v-btn
            color="success"
            dark
            large
            @click.stop="$refs.orderhelp.justbuy(options.efspid)"
            >立即购买</v-btn
          >
        </v-col>
      </v-row>
      <v-divider class="mt-10 mb-10"></v-divider>
    </div>

    <v-simple-table style="width:947px" class="mx-auto">
      <thead>
        <tr height="150px">
          <th class="d-none d-sm-table-cell"></th>
          <th></th>
          <th class="text-center">
            <v-img
              src="https://static.eset.com.cn/estore/img/Boxes/ESSP.png"
              max-width="119"
            ></v-img>
          </th>
          <th class="text-centert">
            <v-img
              src="https://static.eset.com.cn/estore/img/Boxes/EIS.png"
              max-width="119"
            ></v-img>
          </th>
          <th class="text-left">
            <v-img
              src="https://static.eset.com.cn/estore/img/Boxes/EAV.png"
              max-width="119"
            ></v-img>
          </th>
        </tr>
        <tr>
          <th class="d-none d-sm-table-cell"></th>
          <th class="text-h4 text-left">产品功能</th>
          <th class="text-center text-h6">
            终极保护
            <div class="text-center text-body-2">ESET 安全套装旗舰版</div>
          </th>
          <th class="text-center text-h6">
            进阶保护
            <div class="text-center text-body-2">ESET INTERNET SECURITY</div>
          </th>
          <th class="text-center text-h6">
            基本保护
            <div class="text-center text-body-2">ESET NOD32防病毒</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in desserts" :key="item.name" height="69px" class>
          <td class="d-none d-sm-table-cell">
            <div v-if="item.icon" class="d-none d-sm-inline">
              <v-icon>{{ item.icon }}</v-icon>
              <v-icon color="red">{{ item.arrow }}</v-icon>
            </div>
          </td>
          <td class="text-h7 text-left" style="color:#5d666f">
            <div class="font-weight-black">{{ item.title }}</div>
            <div>{{ item.subtitle }}</div>
          </td>
          <td class="text-center">
            <v-icon v-if="item.ESSP" color="orange">mdi-check-bold</v-icon>
            <div v-else>—</div>
          </td>
          <td class="text-center">
            <v-icon v-if="item.EIS" color="orange">mdi-check-bold</v-icon>
            <div v-else>—</div>
          </td>
          <td class="text-center">
            <v-icon v-if="item.EAV" color="orange">mdi-check-bold</v-icon>
            <div v-else>—</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-btn color="primary" text @click="test()" v-if="!isProduction"
      >testbutton</v-btn
    >
    <orderhelp ref="orderhelp"></orderhelp>
  </div>
</template>
<style>
.MyRadio label {
  font-size: 0.8em;
}
</style>
<script>
import orderhelp from "../components/OrderHelp";
export default {
  components: {
    orderhelp,
  },
  mounted() {
    console.log(
      process.env.NODE_ENV.trim() === "production",
      process.env.NODE_ENV
    );
  },
  props: {
    oid: { type: String },
    safecode: { type: String },
  },

  methods: {
    test: async function() {
      // router.push('/order')
      //this.$route.go({path:'/order',
      //                   query:{oid:'11312313',safecode:'aaaa'}})
      //console.log(this.$route)
      this.$router.push({
        path: "/orders",
        query: { oid: "202012540", safecode: "787f60e0c0" },
      });
    },
  },
  data() {
    return {
      isProduction: process.env.NODE_ENV === "production",
      orders: {
        pid: "0",
      },
      options: {
        efspid: "47",
        eavpid: "2",
        esspid: "3",
      },
      radioGroup: 0,
      desserts: [
        {
          title: "保护 Windows 系统",
          subtitle: "",
          icon: "mdi-microsoft-windows",
          arrow: "mdi-chevron-down",
          ESSP: 1,
          EIS: 1,
          EAV: 1,
        },
        {
          title: "保护我的计算机",
          subtitle: "免受病毒侵害",
          icon: "",
          ESSP: 1,
          EIS: 1,
          EAV: 1,
        },
        {
          title: "使我免受攻击",
          subtitle: "有效防止勒索软件的攻击",
          icon: "",
          ESSP: 1,
          EIS: 1,
          EAV: 1,
        },
        {
          title: "阻止黑客",
          subtitle: "访问我的计算机",
          icon: "",
          ESSP: 1,
          EIS: 1,
        },
        {
          title: "安全网上购物",
          subtitle: "知道自己的交易安全",
          icon: "",
          ESSP: 1,
          EIS: 1,
        },
        {
          title: "确保家庭网络安全",
          subtitle: "可以用在所有连接的智能设备上",
          icon: "",
          ESSP: 1,
          EIS: 1,
        },
        {
          title: "安全的存储和预填我的密码",
          subtitle: "这样可以不需要记住它们",
          icon: "",
          ESSP: 1,
        },
        {
          title: "加密我的照片",
          subtitle: "以便其它人看不到它们",
          icon: "",
          ESSP: 1,
        },
        {
          title: "兼容 Android 系统",
          icon: "mdi-android",
          arrow: "mdi-chevron-right",
          ESSP: 1,
          EIS: 1,
        },
        {
          title: "兼容 macOS",
          icon: "mdi-apple-finder",
          arrow: "mdi-chevron-right",
          ESSP: 1,
          EIS: 1,
          EAV: 1,
        },
        {
          title: "兼容 Linux",
          icon: "mdi-linux",
          arrow: "mdi-chevron-right",
          ESSP: 1,
          EIS: 1,
          EAV: 1,
        },
      ],
    };
  },
};
</script>
