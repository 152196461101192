<template>
  <div class="pt-10">
    <div style="width:947px" class="mx-auto grey--text text--darken-2 text-caption">


      <v-row no-gutters align="center">
        <v-col cols="2" class="text-right d-none d-sm-inline">
          <v-img src="/img/Boxes/ESSP.png" width="119"></v-img>
        </v-col>

        <v-col class="text-left">
          <span class="text-h5">ESET File Security</span>
          <span class="text-body-1 d-flex d-sm-inline grey--text text--darken-1"> 适用于 MICROSOFT WINDOWS SERVER 的服务器防病毒软件</span>
          <div>内置防病毒 | 储存扫描 | 64位扫描引擎 | UEFI扫描仪</div>
          <div>漏洞利用防护 | Hyper-V扫描 | 集群支持 | 模块化安装</div>
          <v-radio-group v-model="options.efspid" class="MyRadio" dense>
            <v-radio value="47" label>
              <template v-slot:label>
                <div class="body-2">
                  ESET File Security 3年期/1台计算机：2180.00元
                  <span
                    class="orange white--text rounded-sm ml-2 mt-2 pl-1 pr-1 caption"
                  >适用于Windows 服务器</span>
                </div>
              </template>
            </v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="d-none d-sm-inline"></v-col>
        <v-col>
          只支持下列操作系统：
          <br />Microsoft Windows Server 2016/2012 R2 / 2012/2008 R2 / 2008/2003 R2 / 2003
          <br />Microsoft Windows小型企业服务器2011/2008/2003 R2 / 2003
          <br />Microsoft Windows服务器核心2016/2012/2008 R2 / 2008
          <br />注意:本商品不支持7天无理由退货,仅限在中国大陆地区激活使用.
          <br />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2"></v-col>
        <v-col>
          <v-btn color="success" dark large @click.stop="$refs.orderhelp.justbuy(options.efspid)">立即购买</v-btn>
        </v-col>
      </v-row>
<v-divider class="mt-10 mb-10"></v-divider>
      <v-row>
        <v-col>
          <v-list-item class="pa-0">
            <v-list-item-avatar color="grey lighten-3">
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">预防勒索软件</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item class="pa-0">
            <v-list-item-avatar color="grey lighten-3">
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">零日威胁检测</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item class="pa-0">
            <v-list-item-avatar color="grey lighten-3">
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">防止数据泄露</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
        <v-col>
          <v-list-item class="pa-0">
            <v-list-item-avatar color="grey lighten-3">
              <v-icon>mdi-check-bold</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="headline">僵尸网络保护</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>

    </div>

    <orderhelp ref="orderhelp"></orderhelp>
  </div>
</template>
<style >
.MyRadio label {
  font-size: 0.8em;
}
</style>
<script>
import orderhelp from "../components/OrderHelp";
export default {
  components: {
    orderhelp,
  },
  mounted() {
    console.log(
      process.env.NODE_ENV.trim() === "production",
      process.env.NODE_ENV
    );
  },
  props: {
    oid: { type: String },
    safecode: { type: String },
  },

  methods: {
    test: async function () {
      // router.push('/order')
      //this.$route.go({path:'/order',
      //                   query:{oid:'11312313',safecode:'aaaa'}})
      //console.log(this.$route)
      /*this.$router.push({
        path: "/orders",
        query: { oid: "202012540", safecode: "787f60e0c0" },
      });
    */

     //this.$refs.orderhelp.justbuy
    },
  },
  data() {
    return {
      isProduction: process.env.NODE_ENV === "production",
      orders: {
        pid: "0",
      },
      options: {
        efspid: "47",
        eavpid: "1",
        esspid: "45",
      },
      radioGroup: 0,
    };
  },
};
</script>
