<template>
  <v-app>

    <v-app-bar app 
      color="#424d56"
      dark
    >
      <svg class="logo">
        <use xlink:href="#icon-eset-logo-print" />
      </svg>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab v-for="item in items" :key="item.title" :to="item.to">{{ item.title }}</v-tab>
      </v-tabs>
    </v-app-bar>

<v-main>
 <v-container fluid>
      <router-view></router-view>
 </v-container>
</v-main>
    <v-footer app dark padless absolute class="font-weight-medium">

      

<v-card flat tile class="text-center mx-auto" width="100%">
          <v-row>
          <v-col cols="12" sm="9">
        <v-card-text>
          <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
            <v-icon size="24px">{{ icon }}</v-icon>
          </v-btn>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          此处用到的产品商标归ESET spol. s r.o.或 ESET 有限责任公司合法所有。
          <br />
          <a href="http://beian.miit.gov.cn/" style="color:#fff">京ICP备13003359号-2</a> 京公网安备1101054278
        </v-card-text>


          </v-col>
          <v-col cols="auto"  class="text-center mx-auto">
            微信客服
           <v-img src="/img/wechat.png" width="110"></v-img>
          </v-col>


        </v-row>
                <v-divider></v-divider>
        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} —
          <strong>安徽易视线科技有限责任公司</strong>
        </v-card-text>
        
      </v-card>

        
    </v-footer>
  </v-app>
</template>
<style >
.logo {
  fill: #fff;
  height: 50%;
  width: 96px;
  margin: 0 20px 0 0;
}
</style>
<script>
import "../src/icons/eset-logo-print.svg";

export default {
  data() {
    return {
      tab: null,
      items: [
        { title: "在线购买", to: "/" },
        { title: "商业用途", to: "/Business" },
        { title: "订单查询", to: "/orders" },
       // { title: "下载", to: "/Download" },
      ],
      icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    };
  },
};
</script>