import axios from 'axios'
const http=axios.create({
    baseURL:process.env.VUE_APP_baseURL,
    /*
    process.env.NODE_ENV === 'production'?
    'https://api.eset.com.cn/':
    'http://127.0.0.1:3000/',
    */
    
    headers: {
        'Cache-Control': 'no-cache'
    }

})

export default http
