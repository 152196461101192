import Vue from 'vue'
import App from './App.vue'
//import VueSimpleAlert from "vue-simple-alert";

import './plugins/element.js'
import router from './router'
import vuetify from './plugins/vuetify'
import http from './plugins/http'
Vue.prototype.$http=http
//Vue.use(VueSimpleAlert)

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
